import {Injectable} from '@angular/core';
import {MyProfileSummary} from '@Shared/interfaces/my-profile-summary';
import {BehaviorSubject, EMPTY, Observable, catchError, scan, switchMap, tap} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {ProfileApiService} from '../profile-api/profile-api.service';

@Injectable({providedIn: 'root'})
export class ProfileManagementService {
  default: MyProfileSummary = {
    preferredName: '',
    avatarThumbnailImageUri: '',
    userSettings: [],
  };

  private readonly profileSummary$ = new BehaviorSubject<Partial<MyProfileSummary>>(this.default);

  constructor(
    private readonly profileApiService: ProfileApiService,
    private readonly authService: AuthenticationService
  ) {
    this.authService.tokenReceivedEvent$.subscribe(() => {
      this.getProfileSummary();
    });

    if (this.authService.isLoggedIn) {
      this.getProfileSummary();
    }
  }

  get profileSummary() {
    return this.profileSummary$.asObservable().pipe(
      scan((oldValue: Partial<MyProfileSummary>, newValue: Partial<MyProfileSummary>) => {
        return {...oldValue, ...newValue};
      })
    );
  }

  getProfileSummary(): Observable<Partial<MyProfileSummary>> {
    this.profileApiService
      .getMyProfile()
      .pipe(
        tap((profile) => {
          this.profileSummary$.next({
            preferredName: profile.preferredName,
            avatarThumbnailImageUri: profile.userAvatarImageThumbnail,
            userSettings: profile.userSettings,
          });
        }),
        switchMap((profile) => {
          if (!profile.activatedInPlatform) {
            return this.profileApiService.activateUserInPlatform();
          }
          return EMPTY;
        }),
        catchError(() => EMPTY)
      )
      .subscribe();
    return this.profileSummary;
  }

  setProfileSummary(profileSummary: Partial<MyProfileSummary>): void {
    this.profileSummary$.next(profileSummary);
  }
}
